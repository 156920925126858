<template>
  <div id="ContractManagement">
    <v-card class="ma-4">
      <div class="d-flex justify-space-between pa-3 w-100">
        <div class="d-flex">
          <v-tabs v-model="selectedTabIndex">
            <v-tab v-for="(tab, tabIndex) in tabList" :key="tabIndex">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </div>
      </div>
    </v-card>
    <v-card class="ma-4">
      <TablePage :targetName="'contractTable'" :selectData="selectItem" :thead="thead" :keyGroup="keyGroup" :data="data"
        :haveDetail="true" :haveShare="true" @clickUpdateBtn="clickUpdateBtn" @openDialog="openDialog" @clickDetail="openDetail" @showShare="showShare"
        @search="search" />
      <v-pagination v-if="totalPage > 0" v-model="page" :length="totalPage" :total-visible="7" circle
        @input="changePage"></v-pagination>
      <v-dialog v-model="dialog" width="90%" max-width="1200" @click:outside="clickCloseBtn"
        @keydown.esc="clickCloseBtn">
        <component :is="this.createBtn.value" :action="action" :data="selectedData" @clickCloseBtn="clickCloseBtn"
          @clickConfirmBtn="clickConfirmBtn"></component>
      </v-dialog>
      <v-dialog v-model="detailDialog" width="90%" max-width="1200">
        <!-- notification and flow dialog -->
        <ContractDetail :selectedContractData="selectedContractData" :refresh="detailDialog" />
      </v-dialog>
    </v-card>
    <v-dialog v-model="shareDialog" width="90%" max-width="1200" @click:outside="closeShareDialog"
      @keydown.esc="closeShareDialog">
      <v-card style="padding:5%; display: flex; flex-direction: column; justify-items: center; align-items: center;">
        <Qrcode-vue :text="shareUrl" level="H"></Qrcode-vue>
        <div class="wrap" style="padding-top: 1rem; padding-bottom: 2rem; display: flex; align-items: center;">
          <v-text-field disabled v-model="shareUrl" style="width: 25vw;"></v-text-field>
          <v-btn @click="copyUrl(shareUrl)" style=" margin-left:1rem;">複製</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import ContractDialog from "@/components/contractManagement/ContractDialog.vue";
import TablePage from "@/components/TablePage.vue";
import ContractDetail from "@/components/contractManagement/ContractDetail.vue";
import {
  getDealerContractsList,
  createDealerContracts,
  updateDealerContracts,
  updateDealerContractsStatus,
  getShareCode,
} from "@/apis/DealerContracts";
import QrcodeVue from "vue-qrcode-component";

export default {
  name: "ContractManagement",
  components: {
    TablePage,
    ContractDialog,
    ContractDetail,
    QrcodeVue
  },
  data() {
    return {
      createUpdateFunctionArray: [createDealerContracts, updateDealerContracts],
      params: {},
      totalPage: 0,
      detailDialog: false,
      selectItem: [],
      action: "",
      dialog: false,
      selectedTabIndex: 0,
      shareDialog: false,
      shareUrl: "",
      tabList: [
        {
          name: "合約",
          value: "ContractDialog",
        },
      ],
      selectedData: {},
      thead: [],
      data: [],
      keyGroup: [],
      createBtn: {
        name: "",
        value: "",
      },
      dealerId: -1,
      selectedContractData: {},
    };
  },
  methods: {
    changePage() {
      this.params.page = this.page;
      this.getDealerContract();
    },
    openDetail(data) {
      this.selectedContractData = data;
      localStorage.setItem(
        "day",
        data.passAwayDate.toString().split("").splice(0, 8).toString().replaceAll(",", "")
      );
      this.detailDialog = true;
    },
    closeShareDialog() {
      this.shareDialog = false;
    },
    async haveSelectedTab() {
      this.createBtn = this.tabList[this.selectedTabIndex];
      this.selectItem = [
        { text: "編號", value: "id" },
        { text: "狀態", value: "status" },
      ];
      this.thead = [
        "序號",
        "案名",
        "稱謂",
        "性別",
        "安葬方式",
        "過世日期",
        "狀態",
        "客戶名稱",
        "告別式地點",
        "停靈地點",
        "類型",
        "總金額",
        "是否顯示金額",
        "建立時間",
        "建立人員",
        "修改時間",
        "修改人員",
        "操作",
      ];
      this.keyGroup = [
        "id",
        "name",
        "title",
        "gender_revise",
        "burialType_revise",
        "passAwayDate",
        "status_revise",
        "customerName",
        "memorialCeremonyAddress",
        "morgueAddress",
        "type_revise",
        "totalAmount",
        "isShowAmount_revise",
        "createTime",
        "createUser",
        "updateTime",
        "updateUser",
      ];
      this.getDealerContract();
    },
    async getDealerContract() {
      this.$store.commit("setLoading", true);
      const res = await getDealerContractsList(this.dealerId, this.params);
      this.totalPage = res.data.result.pageCount;
      this.page = res.data.result.currentPage;
      this.data = res.data.result.results;
      this.reviseDealerContractData();
      this.$store.commit("setLoading", false);
    },
    statusJudge(number) {
      // Unknown = 0,
      // Draft, // 協調中
      // Processing, // 進行中
      // Closed, // 已圓滿
      // Cancel // 已取消

      switch (number) {
        case 1:
          return "協調中";
        case 2:
          return "進行中";
        case 3:
          return "已圓滿";
        case 4:
          return "已取消";
        default:
          return "";
      }
    },
    genderJudge(number) {
      if (number === 1) {
        return "男";
      }
      return "女";
    },
    burialTypeJudge(number) {
      switch (number) {
        case 0:
          return "無";
        case 1:
          return "火葬";
        case 2:
          return "土葬";
        case 3:
          return "樹葬";
        case 4:
          return "海葬";
        default:
          return "";
      }
    },
    typeJudge(number) {
      switch (number) {
        case 0:
          return "未知";
        case 1:
          return "包套";
        case 2:
          return "逐項計費";
        default:
          return "";
      }
    },
    reviseDealerContractData() {
      this.data.forEach((item, index) => {
        this.data[index].gender_revise = this.genderJudge(item.gender);
        this.data[index].burialType_revise = this.burialTypeJudge(item.burialType);
        this.data[index].status_revise = this.statusJudge(item.status);
        this.data[index].type_revise = this.typeJudge(item.type);
        this.data[index].isShowAmount_revise = item.isShowAmount ? "顯示" : "不顯示";
      });
    },
    async search(enableBoolean, nowSelectItem, data) {
      this.params = {};
      if (data) {
        this.params[nowSelectItem] = data;
      }
      this.getDealerContract();
    },
    clickUpdateBtn(index) {
      this.openDialog("update", index);
    },
    filterData(data) {
      const rules = [
        "address",
        "arrivalDate",
        "burialType",
        "createTime",
        "createUser",
        "customerId",
        "customerName",
        "dealerId",
        "gender",
        "id",
        "isShowAmount",
        "memorialCeremonyAddress",
        "morgueAddress",
        "name",
        "passAwayDate",
        "passAwayLunarDate",
        "relationKeyId",
        "status",
        "tel",
        "title",
        "totalAmount",
        "type",
        "updateTime",
        "updateUser",
      ];
      let result = {};
      rules.forEach((item) => {
        if (data[item] !== undefined) {
          result[item] = data[item];
        }
      });
      return result;
    },
    async clickConfirmBtn(data, isChangeStatus) {
      let datas = this.filterData(data);
      const index = this.action === "create" ? 0 : 1;
      datas.dealerId = this.dealerId;
      if (isChangeStatus) {
        await updateDealerContractsStatus(data.dealerId, data.id, data.status);
      }
      const res = await this.createUpdateFunctionArray[index](datas);
      if (res.status === 200) {
        this.clickCloseBtn();
        this.getDealerContract();
      }
    },
    clickCloseBtn() {
      this.dialog = false;
      this.action = "";
      this.selectedData = {};
    },
    openDialog(action, index) {
      this.selectedData = {};
      this.action = action;
      this.dialog = true;
      if (typeof index === "number") {
        this.selectedData = _.cloneDeep(this.data[index]);
      }
    },
    async showShare(item) {
        this.$store.commit("setLoading", true);
        const code = await getShareCode(item.id);
        this.shareUrl = `${this.baseURL}Regist?shareCode=${code.data.result}`;

        this.$store.commit("setLoading", false);

        this.shareDialog = true;
    }
  },
  created() {
    this.dealerId = localStorage.getItem("userId");
    this.params = {};
    this.haveSelectedTab();
  },
};
</script>
