import http from "@/http/axios";
import { judgeError, queryFilter } from "./Common";
import { error, success } from "@/utils/notification";

export async function getDealerContractsList(dealerId, query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/DealerContracts/${dealerId}/List${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function getDealerContractsSingle(query) {
  const queryString = queryFilter(query);
  const res = await http.get(`/api/DealerContracts${queryString}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function createDealerContracts(data) {
  const res = await http.post(`/api/DealerContracts/${data.dealerId}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function updateDealerContracts(data) {
  const res = await http.put(`/api/DealerContracts/${data.id}`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function disableDealerContracts(dealerId, id) {
  const res = await http.post(`/api/DealerContracts/${dealerId}/${id}/Disable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function enableDealerContracts(dealerId, id) {
  const res = await http.post(`/api/DealerContracts/${dealerId}/${id}/Enable`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function getDealerContractsDetail(dealerId, id) {
  const res = await http.get(`/api/DealerContracts/${dealerId}/Details/${id}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}

export async function createDealerContractsDetail(id, data) {
  const res = await http.post(`/api/DealerContracts/${id}/Details`, data);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function updateDealerContractsStatus(dealerId, id, statusId) {
  const res = await http.post(`/api/DealerContracts/${dealerId}/Status/${id}?request=${statusId}`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  } else {
    success();
  }
  return res;
}

export async function getShareCode(id) {
  const res = await http.get(`/api/DealerContracts/${id}/ShareCode`);
  if (!judgeError(res)) {
    if (!res && !store.state.isCancelToken) {
      error("錯誤", "未知錯誤", "未知錯誤");
    } else {
      error(res.data.error, res.status, res.data.errorMessage);
    }
  }
  return res;
}
